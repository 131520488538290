import { graphql, navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { LayoutContext } from '../components/organisms/layoutProvider';
import { media } from '../utils/media-queries';
import SEO from '../components/organisms/seo';
import RichText from '../components/atoms/richtext';
import Fade from '../components/atoms/fade';
import Float from '../components/atoms/float';

const AboutPage = ({ data }) => {
  const { language, theme, toggleTheme } = useContext(LayoutContext);
  const raw = language === 'en' ? '_rawEn' : '_rawDe';

  useEffect(() => {
    navigate(language === 'en' ? '/about' : '/de/about', { replace: true });
  }, [language]);

  const {
    title,
    description,
    meTitle,
    me,
    world,
    worldTitle,
    past,
    archive,
    firstAboutImage,
    secondAboutImage,
    thirdAboutImage,
  } = data.about.nodes[0];

  const { mail } = data.contact.nodes[0];

  const { illustration3 } = data.overlay.nodes[0];

  return (
    <>
      <SEO siteTitle={title[language] || title.en} />
      <StyledBlog className="spacing-inner">
        <div className="top">
          <div className="desc">
            <p>{title[language] || title.en}</p>
            <br />
            <h1>
              Claudia <br /> Six
            </h1>
          </div>
          <div className="content">
            <div className="images">
              <figure className="first">
                <Img
                  fluid={firstAboutImage.image.asset.fluid}
                  alt={firstAboutImage.alt}
                />
              </figure>
              <div>
                <figure className="second">
                  <Img
                    fluid={secondAboutImage.image.asset.fluid}
                    alt={secondAboutImage.alt}
                  />
                </figure>
                <figure className="third">
                  <Img
                    fluid={thirdAboutImage.image.asset.fluid}
                    alt={thirdAboutImage.alt}
                  />
                </figure>
              </div>
            </div>
            <div className="copy">
              <div className="left">
                <br />
                <br />
                <RichText blocks={description[raw]} />
                <br />
                <br />
                <p className="title">{worldTitle[language]}</p>
                <br />
                <RichText blocks={world[raw]} />
              </div>
              <div className="right">
                <p className="title">{meTitle[language]}</p>
                <br />
                <RichText blocks={me[raw]} />
                <br />
                <br />
                <br />
                <br />
                <p className="title hover">
                  <a href={`mailto:${mail}`}>
                    say hello
                    <br className="keep" />
                    write a message
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <p className="bottom-title">{past[language] || past.en}</p>
          {archive.map((item, i) => (
            <div className="archive-category" key={i}>
              <h2>{item.headline[language]}</h2>
              <div className="items copy-small">
                {item.items.map((archiveItem, archiveI) => (
                  <div className="item" key={archiveI}>
                    <p style={{ fontWeight: 600 }}>
                      {archiveItem.headline[language] ||
                        archiveItem.headline.en}
                    </p>
                    <div style={{ fontFamily: 'var(--font-3)' }}>
                      <RichText blocks={archiveItem.description[raw]} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </StyledBlog>
      {theme === 'light' && (
        <StyledOverlays onClick={toggleTheme} className="overlay">
          <Fade className="overlay1" active={theme === 'light'}>
            <Float speed={100} duration={10}>
              <Img
                fluid={illustration3.image.asset.fluid}
                alt={illustration3.alt}
              />
            </Float>
          </Fade>
        </StyledOverlays>
      )}
    </>
  );
};

const StyledBlog = styled.section`
  min-height: 100vh;
  margin-top: var(--v-spacing-S);
  font-family: var(--font-1);

  .title {
    text-transform: uppercase;
    text-decoration: underline;
  }

  .content {
    .copy {
      .left,
      .right {
        * {
          padding-bottom: var(--spacing-S);
        }
      }

      br {
        display: none;
      }

      .keep {
        display: block;
      }
    }
  }

  .images {
    display: flex;
    padding-bottom: var(--spacing-S);
    padding-top: var(--v-spacing-M);

    .first {
      width: 100%;
      padding-right: var(--spacing-XXS);
    }

    div {
      width: 100%;
      display: flex;
      flex-direction: column;

      .second {
        width: 100%;
        padding-bottom: var(--spacing-XXS);
      }

      .third {
        width: 50%;
      }
    }
  }

  .bottom,
  .archive-category {
    padding-bottom: var(--spacing-S);
  }

  .bottom-title,
  .item,
  .archive-category > h2 {
    padding-bottom: var(--spacing-S);
  }

  @media ${media.L} {
    .top {
      display: flex;
    }

    .desc {
      width: 33%;
    }

    .images {
      padding-top: 0;

      .first {
        width: 40%;
      }

      div {
        .second {
          width: 50%;
        }

        .third {
          width: 25%;
        }
      }
    }

    .content {
      width: 64%;
      padding-bottom: var(--spacing-XL);

      .copy {
        display: flex;

        br {
          display: block;
        }
      }

      .left,
      .right {
        width: 50%;
        padding-right: var(--v-spacing-L);
      }
    }

    .bottom-title {
      padding-bottom: var(--spacing-M);
    }

    .archive-category {
      display: flex;

      h2 {
        width: 33%;
      }

      .items {
        display: flex;
        flex-wrap: wrap;
        width: 64%;

        .item {
          width: 33%;
          padding-bottom: var(--spacing-M);

          div {
            width: 80%;
          }
        }
      }
    }
  }
`;

const StyledOverlays = styled.div`
  padding-top: 25vh;
  height: 200vh;

  .overlay1 {
    width: 90%;
  }
`;

export const query = graphql`
  query About {
    about: allSanityAbout {
      nodes {
        firstAboutImage {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        secondAboutImage {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        thirdAboutImage {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        title {
          de
          en
        }
        description {
          _rawDe
          _rawEn
        }
        meTitle {
          de
          en
        }
        me {
          _rawDe
          _rawEn
        }
        worldTitle {
          de
          en
        }
        world {
          _rawDe
          _rawEn
        }
        past {
          de
          en
        }
        archive {
          headline {
            de
            en
          }
          items {
            headline {
              de
              en
            }
            description {
              _rawDe
              _rawEn
            }
          }
        }
      }
    }
    contact: allSanityContact {
      nodes {
        mail
      }
    }
    overlay: allSanityOverlay {
      nodes {
        illustration3 {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

AboutPage.propTypes = {
  data: PropTypes.object,
};

export default AboutPage;
