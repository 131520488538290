import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ease } from '../../utils/easing';

const Fade = ({ children, active, delay, className, duration = 0.4 }) => (
  <StyledFade
    initial={{ opacity: 0 }}
    animate={{ opacity: active ? 1 : 0 }}
    transition={{ duration, delay, ease: ease.smoothInOut }}
    className={className}
  >
    {children}
  </StyledFade>
);

const StyledFade = styled(motion.div)``;

Fade.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  duration: PropTypes.number,
  delay: PropTypes.number,
  className: PropTypes.string,
};

export default Fade;
