import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Float = ({ children, speed = 50, className, duration = 2 }) => (
  <StyledFloat
    animate={{ y: [speed, 0, speed] }}
    transition={{ duration, repeat: Infinity }}
    className={className}
  >
    {children}
  </StyledFloat>
);

const StyledFloat = styled(motion.div)``;

Float.propTypes = {
  children: PropTypes.node,
  speed: PropTypes.number,
  duration: PropTypes.number,
  className: PropTypes.string,
};

export default Float;
